import ScreenHeading from '../../components/ScreenHeading';
import "./Resume.css";
import cv from "../../assets/cv.jpg";
import DownloadCv from "../../assets/Adarsh11.pdf"

const Resume = (props) => {
  return(
    <div className="resume">
         <ScreenHeading title={"My Resume"} subHeading={"E&P - IT Related"}/>
         <a className="hero-left-download" href={DownloadCv} download={"Adarsh.pdf"} >
                <i class="fas fa-download"></i> Download CV
          </a>
          <img src={cv} alt="" />
         
    </div>
  )
};

export default Resume;

