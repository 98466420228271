import { Fragment } from "react";
import Hero from "./Hero";

const Home = ()=>{
    return(
        <Fragment>
            <Hero/>
        </Fragment>
    )
}
export default Home;