import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Project.css";
import ScreenHeading from "../../components/ScreenHeading";
import prime from "../../assets/prime.jpeg";
import shop from "../../assets/shop.jpeg";
import res from "../../assets/rest.jpeg";
import meat from "../../assets/meat.jpeg";
import biri from "../../assets/biriyani.jpeg";


export default function Projects(props) {
    return (
        <section className="project-section fade-in">
        <ScreenHeading title={"A Bit of My Project"} subHeading={"E&P - IT Related"}/>
          <div className="project-list">
               <div className="project">
                    <img src={prime} alt="" />
                    <p>A clone of Amazone prime model website</p>
                    <a href="https://github.com/adarsh25t/prime-video"> <i class="fas fa-sign-in-alt"></i> View Project</a>
               </div>
               <div className="project">
                    <img src={shop} alt="" />
                    <p>An online e-commerce website</p>
                    <a href="https://github.com/adarsh25t/shop-new-online-shoping"> <i class="fas fa-sign-in-alt"></i> View Project</a>
               </div>
               <div className="project">
                    <img src={res} alt="" />
                    <p>A Restaurent management website</p>
                    <a href="https://github.com/adarsh25t/restaurent-laravel--site"> <i class="fas fa-sign-in-alt"></i> View Project</a>
               </div>
               <div className="project">
                    <img src="https://cdn.pixabay.com/photo/2021/08/04/13/06/software-developer-6521720__480.jpg" alt="" />
                    <p>A online shopping webiste</p>
                    <a href="https://github.com/adarsh25t/zooku25"> <i class="fas fa-sign-in-alt"></i> View Project</a>
               </div>
               <div className="project">
                    <img src={meat} alt="" />
                    <p>A Meat shop website</p>
                    <a href="https://www.tajamart.com/"> <i class="fas fa-sign-in-alt"></i> View Project</a>
               </div>
               <div className="project">
                    <img src={biri} alt="" />
                    <p>A Restaurent static website</p>
                    <a href="https://www.mrbiryani.us/"> <i class="fas fa-sign-in-alt"></i> View Project</a>
               </div>
          </div>
        </section>
    );
  }